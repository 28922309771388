<template>
    <modal ref="modalProgressGris" :titulo="tituloModal" no-aceptar>
        <div v-if="showFecha" class="col px-0 text-general f-17 f-500">
            {{ formatearFecha(fecha[0]) }} - {{ formatearFecha(fecha[1]) }}
        </div>
        <div v-for="(data, index) in datos" :key="index" class="row mx-0 px-5 mb-3 align-items-center">
            <img :src="data.imagen" class="border br-12 obj-cover" width="50" height="50" />
            <div class="col px-2">
                <p class="text-general f-15">
                    {{ data.nombre }}
                </p>
                <p class="text-general2 f-14">
                    {{ exact ? data.valor : tipoCantidad ? agregarSeparadoresNumero(data.valor, 1) : separadorNumero(data.valor) }}
                </p>
                <div class="row mx-0" style="height:10px;">
                    <div class="bg-gris br-20" :style="`width:${data.promedioMax}%`" />
                </div>
                {{ Math.round(data.promedio) }}%
            </div>
        </div>
    </modal>
</template>

<script>
import estadisticas from '~/services/nomina/estadisticas'


export default {
    props: {
        fecha: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            tipo: 1,
            showFecha: false,
            exact: false,
            datos: [],
            tipoCantidad: true,
        }
    },
    computed:{
        tituloModal(){
            let message = 'Empresas con deudas sin liquidar'
            switch (this.tipo){
            case 1:
                message = 'Empresas con deudas sin liquidar'
                break;
            case 2:
                message = `Liquidaciones sin verificación de ${this.$config.proyecto}`
                break;
            case 3:
                message = 'Cupo otorgado a los afiliados'
                break;
            case 4:
                message = 'Solicitudes de afiliación pendientes'
                break;
            case 5:
                message = 'Ventas por convenio vs pago de contado'
                break;
            case 6:
                message = 'Calificación de los pedidos'
                break;
      
            
            default:
                break;
            }

            return message
        },
        params(){
            const params = {
                fechas: this.fecha,
                limited: 0
            }

            if(this.showFecha){
                params['fecha'] = this.fecha
            }

            return params
        }
    },
    methods: {
        async toggle(tipo){
            this.tipo = tipo
            this.tipoCantidad = this.tipo == 4
            this.showFecha = [5, 6].includes(this.tipo)
            this.exact = this.tipo == 6
            await this.buscarDatos()
            this.$refs.modalProgressGris.toggle();
        },
        async buscarDatos(){
            switch (this.tipo){
            case 1:
                await this.getDataDeudasSinLiquidar()
                break;
            case 2:
                await this.getDataLiquidacionesSinVerificacion()
                break;
            case 3:
                await this.getDataCupoOtorgado()
                break;
            case 4:
                await this.getDataSolicitudes()
                break;
            case 5:
                await this.getDataComprasConvenio()
                break;
            case 6:
                await this.getDataCalificacionPedidos() 
                break;
          
            }
        },
        async getDataDeudasSinLiquidar(){
            try {
                const { data } = await estadisticas.deudasSinLiquidar(this.params);
                const total = _.sumBy(data, 'suma')
                const max = _.maxBy(data, 'suma').suma || 0
                
                this.datos = data.map(el => {
                    el.valor = el.suma
                    el.promedio = total > 0 ? (Math.round(el.suma * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.suma * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataLiquidacionesSinVerificacion(){
            try {
                const { data } = await estadisticas.liquidacionesSinVerificacion(this.params);
                const total = _.sumBy(data, 'suma')
                const max = _.maxBy(data, 'suma').suma || 0
                
                this.datos = data.map(el => {
                    el.valor = el.suma
                    el.promedio = total > 0 ? (Math.round(el.suma * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.suma * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataCupoOtorgado(){
            try {
                const { data } = await estadisticas.cupoOtorgado(this.params);
                const total = _.sumBy(data, 'cupo')
                const max = _.maxBy(data, 'cupo').cupo || 0
                
                this.datos = data.map(el => {
                    el.valor = el.cupo
                    el.promedio = total > 0 ? (Math.round(el.cupo * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.cupo * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataSolicitudes(){
            try {
                const { data } = await estadisticas.solicitudes(this.params);

                const total = _.sumBy(data, 'count')
                const max = _.maxBy(data, 'count').count || 0
                
                this.datos = data.map(el => {
                    el.valor = el.count
                    el.promedio = total > 0 ? (Math.round(el.count * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.count * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataComprasConvenio(){
            try {
                const { data } = await estadisticas.comprasConvenio(this.params);

                const total = _.sumBy(data, (el) => el.sum_sin_convenio + el.sum_convenio)
                const maxFind = _.maxBy(data, (el) => el.sum_sin_convenio + el.sum_convenio) || 0
                const max = (maxFind.sum_sin_convenio + maxFind.sum_convenio)

                
                this.datos = data.map(el => {
                    el.valor = el.sum_sin_convenio + el.sum_convenio
                    el.promedio = total > 0 ? (Math.round(el.valor * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.valor * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataCalificacionPedidos(){
            try {
                const { data } = await estadisticas.calificacionPedidos(this.params);

                const total = _.sumBy(data, el => Number(el.promedio))
                const max = _.maxBy(data, el => Number(el.promedio)).count || 0
                
                this.datos = data.map(el => {
                    el.valor = Number(el.promedio)
                    el.promedio = total > 0 ? (Math.round(el.valor * 100) / total) : 0
                    el.promedioMax = max > 0 ? (Math.round(el.valor * 100) / max) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>